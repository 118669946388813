import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authServices from "../services/AuthService";

const initialState = { saved: [], applied: [] };

export const getSavedJobs = createAsyncThunk(
  "jobs/getSavedJobs",
  async (userId) => {
    const response = await authServices.getSavedJobs(userId);
    return response.data;
  }
);

export const getAppliedJobs = createAsyncThunk(
  "jobs/getAppliedJobs",
  async (userId) => {
    const response = await authServices.getAppliedJobs(userId);
    return response.data;
  }
);

export const applyJob = createAsyncThunk(
  "jobs/applyJob",
  async ({ id, documents }, { rejectWithValue }) => {
    let {
      data: { job, error },
    } = await authServices.applyJob(id, documents);

    if (error) {
      return rejectWithValue(error);
    }
    job = {
      _id: job._id,
      title: job.title,
      description: job.description,
      recruiter: job.recruiter,
      appliedDate: Date.now(),
      status: "underReview",
    };
    return job;
  }
);

export const saveJob = createAsyncThunk(
  "jobs/saveJob",
  async ({ userId, job }) => {
    await authServices.saveJob(userId, job._id);
    return { ...job, savedDate: Date.now() };
  }
);

export const removeSavedJob = createAsyncThunk(
  "jobs/unsaveJob",
  async ({ userId, job }) => {
    await authServices.unSaveJob(userId, job._id);
    return job;
  }
);

export const jobSlice = createSlice({
  name: "job",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getSavedJobs.fulfilled]: (state, { payload }) => {
      state.saved = payload;
    },
    [getAppliedJobs.fulfilled]: (state, { payload }) => {
      state.applied = payload;
    },
    [saveJob.fulfilled]: (state, { payload }) => {
      state.saved.push(payload);
    },
    [removeSavedJob.fulfilled]: (state, { payload }) => {
      state.saved = state.saved.filter((item) => item._id !== payload._id);
    },
    [applyJob.fulfilled]: (state, { payload }) => {
      state.applied.push(payload);
    },
  },
});

export default jobSlice.reducer;
