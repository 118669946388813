import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Logo from "../../assets/images/Logo_Hirenow.png";
import { AiFillTwitterCircle, AiOutlineInstagram } from "react-icons/ai";
import { ImFacebook2 } from "react-icons/im";
import "../../assets/styles/Footer.css";

function Footer() {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const handleOnClick = (link) => () => {
    navigate(`${link}`);
  };

  return (
    <Row className="m-0 bg-white w-100 p-4 d-flex flex-sm-column flex-md-row justify-content-center align-items-center">
      <Col
        className="footer_body m-0 d-flex flex-column justify-content-center"
        xs={5}
        md={2}
      >
        <img
          src={Logo}
          alt="Logo HireNow"
          className="cursor_pointer"
          onClick={handleOnClick("/")}
        />

        <div className="mt-4 w-100">
          <AiFillTwitterCircle
            color="#999999"
            size={25}
            className="me-2 cursor_pointer footer_social_icon"
          />
          <AiOutlineInstagram
            color="#999999"
            size={25}
            className="me-2 cursor_pointer footer_social_icon"
          />
          <ImFacebook2
            color="#999999"
            size={25}
            className="cursor_pointer footer_social_icon"
          />
        </div>
      </Col>
      <Col
        className={`footer_body p-5 d-flex align-items-center justify-content-around flex-sm-column flex-md-row`}
        xs={12}
        md={7}
      >
        <span
          onClick={handleOnClick("PrivacyPolicy")}
          className="cursor_pointer text-muted fs-18"
        >
          Privacy Policy
        </span>
        <span
          onClick={handleOnClick("TermsOfUse")}
          className="cursor_pointer text-muted fs-18"
        >
          Terms of Use
        </span>
        <span
          onClick={handleOnClick("CookiePolicy")}
          className="cursor_pointer text-muted fs-18"
        >
          Cookie Policy
        </span>

        {user._id ? (
          <span
            onClick={handleOnClick("Faq")}
            className="cursor_pointer text-muted fs-18"
          >
            Help Center
          </span>
        ) : (
          <span
            onClick={handleOnClick("FaqHome")}
            className="cursor_pointer text-muted fs-18"
          >
            Help Center
          </span>
        )}
      </Col>
      <Col className=" text-muted fs-16" xs={5} md={2}>
        <p className="m-0 p-0">2150 Town Square</p>
        <p className="m-0 p-0"> Place, Suite 200</p>
        <p className="m-0 p-0"> Sugar Land, Texas</p>
        <p className="m-0 p-0"> 77479</p>
      </Col>
    </Row>
  );
}

export default Footer;
