export default [
  { label: "Please select industry", value: "" },
  {
    label: "Staffing and Recruiting",
    value: "staffing",
  },
  {
    label: "Technology, information and Internet",
    value: "technology",
  },
  {
    label: "Software Development",
    value: "software",
  },
  {
    label: "Architecture and Planning",
    value: "architecture",
  },
  {
    label: "Civil Engineering",
    value: "civil_engineering",
  },
  {
    label: "Banking",
    value: "banking",
  },
  {
    label: "IT Services and IT Consulting",
    value: "it",
  },
  {
    label: "Hospitality",
    value: "hospitality",
  },
  {
    label: "Hospitals and Health Care",
    value: "hospital_care",
  },
  {
    label: "Construction",
    value: "construction",
  },
  {
    label: "Retail",
    value: "retail",
  },
  {
    label: "Financial Services",
    value: "financial",
  },
  {
    label: "Business Consulting and Services",
    value: "consulting",
  },
  {
    label: "Oil and Gas",
    value: "oil",
  },
];
