import { createSlice } from "@reduxjs/toolkit";
import { applyJob } from "./jobSlice";

const initialState = {};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    addAlert: (state, action) => {
      return { ...state, ...action.payload };
    },
    removeAlert: (state) => {
      return (state = {});
    },
  },
  extraReducers: {
    [applyJob.rejected]: (state, action) => {
      state.variant = "danger";
      state.message = action.payload;
    },
  },
});

export const { addAlert, removeAlert } = alertSlice.actions;

export default alertSlice.reducer;
