import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import "../../assets/styles/Users.css";
import verificationBar from "../../assets/images/verification-bar.png";
import loadingBar from "../../assets/images/loading-bar.gif";
import authServices from "../../services/AuthService";
import { useNavigate } from "react-router-dom";

function EmailVerificationReSent() {
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const closeModal = () => {
    setShow(false);
    navigate("/");
  };

  const navigateToPage = (page) => () => {
    navigate(`/${page}`);
  };

  const handleResendEmailVerification = () => {
    if (!email || !email?.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      return alert("Please enter a valid email");
    }
    setIsLoading(true);
    authServices
      .resendEmailVerification(email)
      .then((res) => {
        if (res.data.error) {
          return alert(res.data.error);
        }
        navigate(`/email_verification_sent?email=${email}`);
      })
      .catch((error) => {
        alert(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal show={show} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className=" text-center fw-600">Re-send verification email</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-2 px-4">
          <p className="text-muted text-center">
            Your email has not been verified yet. Please enter your email in
            order to proceed to create account with Hirenow
          </p>
          <img src={verificationBar} alt="verification-bar" className="w-100" />
          <p className="text-center mt-4 fw-600">Email Address</p>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
          />
          <div className="w-100 d-flex justify-content-center">
            {isLoading ? (
              <img className="mt-3 mb-4" src={loadingBar} />
            ) : (
              <button
                className="orange_btn w-75 mb-4 mt-3"
                onClick={handleResendEmailVerification}
              >
                Verify Your Email
              </button>
            )}
          </div>
          <div className="text-center mt-5 mb-3">
            Already have an account?{" "}
            <span
              className="text-purple cursor-pointer"
              onClick={navigateToPage("signin")}
            >
              Login Now
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default EmailVerificationReSent;
