import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import  "../../assets/styles/common.css";

import candidate from "../../assets/images/candidate.png";
import selected_as_candidate from "../../assets/images/selected_as_candidate.png";
import employer from "../../assets/images/employer.png";
import selected_as_employer from "../../assets/images/selected_as_employer.png";

function AccountType({accountType, setAccountType, userType, setUserType, accountTypeCallback}) { 
    return (
      <>             
        <Modal
          size="md"
          show={accountType}   
          dialogClassName="custom_modal_size"
          centered
        >
            <Modal.Header closeButton  onHide={()=>setAccountType(false)}>
            <Modal.Title>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="m-4 p-2">
                      <span className="secondary_color_text fw-bold">
                      Hello, which one of this best describe you?
                      </span>
                      <Row className="mt-4 justify-content-between">                
                            { userType == '' || userType == 'employer' ?                 
                            (<Col className="cursor_pointer" sm={10} md={6} onClick={() => setUserType('candidate')}>
                              <div className="account_type not-selected-type text-nowrap d-flex">
                                <img src={candidate} />
                                <div className="d-block ms-4"><div className="fs-14">Register as</div><div className="fs-18 mt-1 fw-bold ">Candidate</div></div>
                              </div>           
                            </Col> ) 
                            :       
                          
                            (<Col className="cursor_pointer" sm={10} md={6} onClick={() => setUserType('candidate')}>
                              <div className="account_type selected-type text-nowrap d-flex">
                                <img src={selected_as_candidate} />
                                <div className="d-block ms-4"><div className="fs-14">Register as</div><div className="fs-18 mt-1 fw-bold ">Candidate</div></div>
                              </div>           
                            </Col> )
                          }
                          { userType == '' || userType == 'candidate' ?
                            
                            (<Col className="cursor_pointer" sm={10} md={6} onClick={() => setUserType('employer')}> 
                              <div className="account_type not-selected-type text-nowrap d-flex">
                                <img src={employer} />
                                <div className="d-block ms-3"><div className="fs-14">Register as</div><div className="fs-16 mt-1 fw-bold">Employer</div></div>
                              </div>           
                            </Col> )    
                            :    
                        
                            (<Col className="cursor_pointer" sm={10} md={6} onClick={() => setUserType('employer')}>
                              <div className="account_type selected-type text-nowrap d-flex">
                                <img src={selected_as_employer} />
                                <div className="d-block ms-3"><div className="fs-14">Register as</div><div className="fs-16 mt-1 fw-bold">Employer</div></div>
                              </div>          
                            </Col>)
                          }
                          <div className="mt-4 d-flex justify-content-between">
                            <button type="submit" onClick={() => accountTypeCallback()} className="orange_btn w-100">
                              Continue
                            </button>
                          </div>
                      </Row> 
                </div>
            </Modal.Body>
        </Modal>
      </>
    );
}
export default AccountType;
