import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Home from "../../pages/home/Home";
import Loader from "../../Loader";
import authServices from "../../services/AuthService";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../slices/userSlice";
import { addAlert } from "../../slices/alertSlice";
import { getSavedJobs, getAppliedJobs } from "../../slices/jobSlice";
import { getSavedCandidates } from "../../slices/candidateSlice";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = () => {
  let user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const handleNotLoggedIn = () => {
      dispatch(
        addAlert({ variant: "success", message: "Please log in first!" })
      );
      navigate("/signin");
    };
    const checkLogin = async () => {
      if (user._id) {
        setIsLoading(false);
        return;
      }
      const token = localStorage.getItem("token");
      if (!token) {
        handleNotLoggedIn();
        return;
      }
      try {
        const res = await authServices.checkLogin(token);
        if (res.data.message === "User Logged In") {
          dispatch(login(res.data.user));

          if (res.data.user.type === "candidate") {
            dispatch(getSavedJobs(res.data.user._id));
            dispatch(getAppliedJobs(res.data.user._id));
          } else {
            dispatch(getSavedCandidates(res.data.user._id));
            dispatch(getAppliedJobs(res.data.user._id));
          }
          setIsLoading(false);
          return;
        }
        handleNotLoggedIn();
      } catch (error) {
        localStorage.removeItem("token");
        handleNotLoggedIn();
      }
    };
    checkLogin();
  }, []);
  if (isLoading) return <Loader />;
  if (!user._id) {
    return <Home />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
