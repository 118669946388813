import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      return (state = { ...action.payload });
    },
    update: (state, action) => {
      return { ...state, ...action.payload };
    },

    logout: (state) => {
      return (state = {});
    },
  },
});

export const { login, logout, update } = userSlice.actions;

export default userSlice.reducer;
