import React, { useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import "../../assets/styles/Users.css";
import verificationBar from "../../assets/images/verification-bar.png";
import { useNavigate } from "react-router-dom";

function EmailVerificationSent() {
  const queryParams = new URLSearchParams(window.location.search);
  let email = queryParams.get("email");
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const closeModal = () => {
    setShow(false);
    navigate("/");
  };

  const navigateToPage = (page) => () => {
    navigate(`/${page}`);
  };

  return (
    <Modal show={show} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h2 className=" text-center fw-600">Verify Your Email</h2>
        </Modal.Title>
      </Modal.Header>
      {/* <p className="mt-5 text-center text-muted">Enter Email Address</p> */}

      {/* {isLinkSent ? (
        <Row className="mt-2">
          <h5 className="text-center">
            <AiFillCheckCircle color="#0dc16e" /> A verification link has been
            sent to your email account
          </h5>
          <br className="login_line my-3" />
          <p className="text-center">
            Please click on the link that has just been sent to your email
            account to verify your email and continue the registration process.
          </p>
        </Row>
      ) : (
      
      )} */}
      <div className="mt-2 px-5">
        <p className="text-muted text-center">
          Your verification link was sent to {email}
        </p>
        <img src={verificationBar} alt="verification-bar" className="w-100" />
        <p className="text-center mt-3">
          Didn't get the code?{" "}
          <span
            className="text-purple cursor-pointer"
            onClick={navigateToPage("email_verification_resent")}
          >
            Resend verification email
          </span>
        </p>

        <p className="text-center">
          {" "}
          Still waiting on your link? Remember to check your spam folder!
        </p>
        <br className="login_line mt-5 mb-3" />
        <p className="text-center mt-3 fw-600">
          Not your email?{" "}
          <span
            className="text-purple cursor-pointer"
            onClick={navigateToPage("signup")}
          >
            Click to start over
          </span>
        </p>
        {/* 
          <form className="d-flex flex-column align-items-center mb-5">
            <input
              type="email"
              className="custom_input w-95 d-block"
              placeholder="Enter Email Address"
              // value={email}
              // onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  onSubmit();
                }
              }}
            />
            <button
              type="button"
              className="purple_btn w-25 px-3 py-2 mt-2"
              onClick={onSubmit}
            >
              Send link
            </button>
          </form> */}
      </div>
    </Modal>
  );
}

export default EmailVerificationSent;
