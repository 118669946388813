import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { IoPersonSharp } from "react-icons/io5";
import {
  BsBookmark,
  BsBookmarkFill,
  BsFillTelephoneFill,
  BsFillEnvelopeAtFill,
  BsPinMapFill,
} from "react-icons/bs";
import myResumeIcon from "../../../assets/images/myResumeIcon.png";
import videoPlay from "../../../assets/images/videoplay.svg";
import parse from "html-react-parser";
import authServices from "../../../services/AuthService";
import {
  saveCandidate,
  removeSavedCandidate,
} from "../../../slices/candidateSlice";

const months = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};
const files = {
  resume: "resume",
  resumeVideo: "video resume",
  reference: "reference review",
};
function CandidateModal({
  showCandidateModal,
  toggleCandidateModal,
  candidateIndex,
  setCandidateIndex,
  candidates,
}) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  let selectedCandidate = null;
  if (candidates) selectedCandidate = candidates[candidateIndex];

  const savedCandidates = useSelector((state) => state.candidates.saved);

  const [savedStatus, setSavedStatus] = useState("");
  const [showCandidateDetailsModal, setShowCandidateDetailsModal] =
    useState(false);
  const toggleCandidateDetailsModal = () => {
    setShowCandidateDetailsModal(!showCandidateDetailsModal);
  };
  const [isLoading, setIsLoading] = useState(false);
  const selectPrev = () => {
    setCandidateIndex((prev) => prev - 1);
  };
  const selectNext = () => {
    setCandidateIndex((prev) => prev + 1);
  };

  const openFile = (name) => () => {
    if (selectedCandidate.candidate[name]?.url) {
      window.open(selectedCandidate.candidate[name].url, "_blank");
    } else {
      alert(`No ${files[name]} uploaded by this candidate`);
    }
  };

  const saveUnsaveCandidate = (candidateId) => {
    if (savedStatus) {
      authServices.unSaveCandidate(user._id, candidateId).then((res) => {
        if (res.data.error) {
          return alert(res.data.error);
        }
        setSavedStatus(false);
        return alert("Unsaved the candidate");
      });
    } else {
      authServices.saveCandidate(user._id, candidateId).then((res) => {
        if (res.data.error) {
          return alert(res.data.error);
        }
        setSavedStatus(true);
        return alert("Saved the candidate");
      });
    }
  };
  useEffect(() => {
    if (selectedCandidate) {
      authServices.getSavedCandidates(user._id).then((res) => {
        if (res.data.error) {
          return alert(res.data.error);
        }

        setSavedStatus(
          res.data.candidates.some((item) => item._id === selectedCandidate._id)
        );
      });
    }
  }, [selectedCandidate]);

  if (selectedCandidate) {
    return (
      <>
        <Modal
          size="xl"
          show={showCandidateModal}
          onHide={toggleCandidateModal}
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body className="custom_job_modal_body">
            <Row className="m-0 p-5 job_modal_container">
              <Col
                className="d-flex flex-column modal_left_job_details p-3"
                md={3}
              >
                <div>
                  <p className="fw-bold transparent_btn fs-20 text-start">
                    Reference
                  </p>
                  <div className="company mb-3">
                    <img
                      width="100px"
                      src={
                        selectedCandidate.profileImage
                          ? selectedCandidate.profileImage
                          : "https://www.w3schools.com/howto/img_avatar.png"
                      }
                      alt=""
                    ></img>
                  </div>
                  <span className="fs-12 text-muted">
                    {selectedCandidate.phone && (
                      <p>
                        <BsFillTelephoneFill></BsFillTelephoneFill>
                        <span className="ms-2">{selectedCandidate.phone} </span>
                      </p>
                    )}

                    {selectedCandidate.email && (
                      <p>
                        <BsFillEnvelopeAtFill></BsFillEnvelopeAtFill>
                        <span className="ms-2">{selectedCandidate.email}</span>
                      </p>
                    )}

                    {selectedCandidate.candidate.address && (
                      <p>
                        <BsPinMapFill></BsPinMapFill>
                        <span className="ms-2">
                          {selectedCandidate.candidate &&
                            selectedCandidate.candidate.address &&
                            selectedCandidate.candidate.address.city &&
                            selectedCandidate.candidate.address.city}
                          {selectedCandidate.candidate &&
                          selectedCandidate.candidate.address &&
                          selectedCandidate.candidate.address.state
                            ? ", " + selectedCandidate.candidate.address.state
                            : ""}
                          {selectedCandidate.candidate &&
                          selectedCandidate.candidate.address &&
                          selectedCandidate.candidate.address.country
                            ? ", " + selectedCandidate.candidate.address.country
                            : ""}
                        </span>
                      </p>
                    )}
                  </span>
                </div>

                {selectedCandidate.candidate.resumeVideo?.url && (
                  <div className="d-flex flex-column my-4">
                    <span className="fw-bold">Video Resume</span>
                    <video
                      width="100%"
                      className="rounded"
                      src={selectedCandidate.candidate.resumeVideo.url}
                      controls
                    />
                  </div>
                )}

                <div className="d-flex flex-column cursor_pointer">
                  {savedCandidates.find(
                    (candidate) => candidate._id === selectedCandidate._id
                  ) ? (
                    <span
                      className="fw-bold text-center"
                      onClick={() => {
                        dispatch(
                          removeSavedCandidate({
                            userId: user._id,
                            candidate: selectedCandidate,
                          })
                        );
                      }}
                    >
                      <BsBookmarkFill
                        className="me-2 highlight"
                        color="var(--purple-color)"
                      />
                      Unsave the candidate
                    </span>
                  ) : (
                    <span
                      className="fw-bold text-center"
                      onClick={() => {
                        dispatch(
                          saveCandidate({
                            userId: user._id,
                            candidate: selectedCandidate,
                          })
                        );
                      }}
                    >
                      <BsBookmark
                        className="me-2 highlight"
                        color="var(--purple-color)"
                      />
                      Save the candidate
                    </span>
                  )}

                  <button
                    className="purple_btn py-3 px-2 fs-20 opacity-100 mt-4"
                    onClick={toggleCandidateDetailsModal}
                  >
                    View Details
                  </button>
                </div>
              </Col>
              <Col className="ms-3 d-flex flex-column justify-content-center align-items-start md={9}">
                <Row className="m-0 p-0 w-100 d-flex align-items-center">
                  <Col className="d-flex justify-content-between align-items-center  w-100">
                    <div className="d-block">
                      <h1 className="fw-bold text-capitalize transparent_btn fs-32">
                        {selectedCandidate.fullName}
                      </h1>
                      <div className="font-light">
                        {" "}
                        {selectedCandidate.candidate.title}
                      </div>
                    </div>
                    <div>
                      {candidateIndex > 0 && (
                        <button
                          className="reverse_purple_border_btn py-2 px-3 fs-20 opacity-100"
                          onClick={selectPrev}
                        >
                          Prev
                        </button>
                      )}
                      {candidateIndex < candidates.length - 1 && (
                        <button
                          className="reverse_purple_border_btn py-2 px-3 fs-20 opacity-100 mt-4 ms-2"
                          onClick={selectNext}
                        >
                          Next
                        </button>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row className="m-0 p-0 mt-3">
                  <Col className="d-flex flex-column justify-content-center align-items-start">
                    <span className="fw-bold">Profile</span>
                    <div className="text-muted mt-3">
                      {selectedCandidate.candidate.additionalDetails
                        .personalBio &&
                        parse(
                          selectedCandidate.candidate.additionalDetails
                            .personalBio
                        )}
                    </div>
                  </Col>
                </Row>
                <Row className="m-0 p-0 mt-3">
                  <Col className="d-flex flex-column justify-content-center align-items-start">
                    <p className="fw-bold">Professional Experience</p>
                    {selectedCandidate.candidate.experiences.map(
                      (experience, idx) => (
                        <div key={idx}>
                          <p className="mb-1">
                            {experience.occupation}, {experience.company},{" "}
                            {experience.location}
                          </p>
                          <p className="text-muted">
                            {months[experience.startDate.month]}{" "}
                            {experience.startDate.year} -{" "}
                            {experience.isPresent
                              ? "Present"
                              : `${months[experience.endDate.month]} ${
                                  experience.endDate.year
                                }`}
                          </p>
                          <div className="text-muted">
                            {experience.description &&
                              parse(experience.description)}
                          </div>
                        </div>
                      )
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={showCandidateDetailsModal}
          onHide={toggleCandidateDetailsModal}
          dialogClassName="custom_modal_size"
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body className="custom_job_modal_body p-0">
            <div className="border-bottom p-4">
              <h1 className="fw-bold fs-32">
                Candidate Details - {selectedCandidate.fullName}
              </h1>
            </div>
            <div className="p-4">
              <div
                className="p-4 form-control my-3 d-flex cursor_pointer bg-transparent"
                onClick={openFile("resume")}
              >
                <img
                  src={myResumeIcon}
                  alt="resume"
                  className="me-2"
                  width="30px"
                />
                <span className="ms-2 transparent_btn fs-18">Resume</span>
              </div>{" "}
              <div
                className="p-4 form-control my-3 d-flex cursor_pointer bg-transparent"
                onClick={openFile("resumeVideo")}
              >
                <img
                  src={videoPlay}
                  alt="video"
                  className="me-2"
                  width="30px"
                  height="30px"
                />
                <span className="ms-2 transparent_btn fs-18">Video Resume</span>
              </div>
              <div
                className="p-4 form-control my-3 d-flex cursor_pointer bg-transparent"
                onClick={openFile("reference")}
              >
                <div>
                  <IoPersonSharp color="var(--purple-color)" size={30} />
                  <span className="ms-2 transparent_btn fs-18">
                    References Review
                  </span>
                </div>
              </div>
              <a className="mt-4 d-flex justify-content-between align-items-center">
                <span
                  className="cursor_pointer"
                  onClick={() => saveUnsaveCandidate(selectedCandidate._id)}
                >
                  {savedStatus ? (
                    <button className="purple_btn py-3 px-5 fs-20 opacity-100 w-100 me-2">
                      Unsave
                    </button>
                  ) : (
                    <button className="purple_btn py-3 px-5 fs-20 opacity-100 w-100 me-2">
                      Save
                    </button>
                  )}
                </span>

                {/*  
                <button className="purple_btn py-3 px-5 fs-20 opacity-100 w-100 me-2">
                  Hire 
                </button>*/}
                <div className="justify-content-end">
                  {candidateIndex > 0 && (
                    <button
                      className="reverse_purple_border_btn fs-20 py-3 px-5  ms-2"
                      onClick={selectPrev}
                    >
                      Prev
                    </button>
                  )}
                  {candidateIndex < candidates.length - 1 && (
                    <button
                      className="reverse_purple_border_btn fs-20 py-3 px-5 ms-2"
                      onClick={selectNext}
                    >
                      Next
                    </button>
                  )}
                </div>
              </a>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
  return <></>;
}

export default CandidateModal;
