import authServices from "../../services/AuthService";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../slices/userSlice";

export function MDFormat(MMDDYY) {
  MMDDYY = new Date(MMDDYY);
  MMDDYY.setHours(0, 0, 0, 0);

  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let strDate = "";

  let today = new Date();
  today.setHours(0, 0, 0, 0);

  let yesterday = new Date();
  yesterday.setHours(0, 0, 0, 0);
  yesterday.setDate(yesterday.getDate() - 1);

  let tomorrow = new Date();
  tomorrow.setHours(0, 0, 0, 0);
  tomorrow.setDate(tomorrow.getDate() + 1);

  /*
      console.log(
        MMDDYY.getTime(),
        today.getTime(),
        MMDDYY.getTime() == today.getTime()
      );*/

  if (today.getTime() == MMDDYY.getTime()) {
    strDate = "Today";
  } else if (yesterday.getTime() == MMDDYY.getTime()) {
    strDate = "Yesterday";
  } else if (tomorrow.getTime() == MMDDYY.getTime()) {
    strDate = "Tomorrow";
  } else {
    strDate =
      months[MMDDYY.getMonth()] +
      "-" +
      MMDDYY.getDate() +
      "-" +
      MMDDYY.getFullYear();
  }

  return strDate;
}

export function isValidFileUploaded(file, type) {
  let validExtensions, textExt;
  switch (type) {
    case "image":
      validExtensions = ["png", "jpg", "jpeg", "gif"];
      textExt = "png, jpg, jpeg, gif";
      break;
    case "video":
      validExtensions = ["mp4", "mkv", "x-m4v"];
      textExt = "mp4, mkv, x-m4v";
      break;
    case "document":
      validExtensions = [
        "doc",
        "pdf",
        "docx",
        "txt",
        "rtf",
        "vnd.openxmlformats-officedocument.wordprocessingml.document",
        "msword",
        "plain",
      ];
      textExt = "doc, docx, pdf, txt, rtf";
      break;
  }

  const fileExtension = file.type.split("/")[1];
  let isValid = validExtensions.includes(fileExtension);
  return {
    isValid,
    error:
      !isValid &&
      `File is not valid. Please upload file with extension ${textExt}`,
  };
}

export function isValidSize(file, type) {
  const errors = [];
  let maxSize;
  if (type == "image") maxSize = 6;
  else maxSize = 6;

  const maxUploadSizeInBytes = parseInt(maxSize) * 1024 * 1024;
  let valid = true;
  if (file.size > maxUploadSizeInBytes) {
    valid = false;
    let sizeInKb = maxUploadSizeInBytes / 1024;
    let sizeForHumans =
      sizeInKb < 1024 ? `${sizeInKb} KB` : `${sizeInKb / 1024} MB`;
    alert(`The file exceeds the maximum allowed size of ${sizeForHumans}`);
    //  this.errors.push(
    //     `The file exceeds the maximum allowed size of ${sizeForHumans}`
    //   );
  }
  return valid;
}

export const useAuth = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const token = localStorage.getItem("token");
  if (token) {
    if (!user) {
      authServices
        .checkLogin(token)
        .then((res) => {
          if (res.data.message === "User Logged In") {
            user = res.data.user;
            dispatch(login(res.data.user));
          }
          localStorage.removeItem("token");
        })
        .catch((err) => {
          localStorage.removeItem("token");
        });
    }
  }
  return user;
};

export const checkAuth = () => {
  const token = localStorage.getItem("token");
  let authUser;
  if (!token) return authUser;
  authServices
    .checkLogin(token)
    .then((res) => {
      if (res.data.message === "User Logged In") {
        authUser = res.data.user;
      } else {
        localStorage.removeItem("token");
      }
    })
    .catch((err) => {
      localStorage.removeItem("token");
    });
  return authUser;
};
