import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, lazy, Suspense, useEffect } from "react";
import EmailVerification from "./pages/users/EmailVerification";
import EmailVerificationSent from "./pages/users/EmailVerificationSent";
import EmailVerificationReSent from "./pages/users/EmailVerificationResent";
import Toast from "./components/common/Toast";
import Navbar from "./components/common/Navbar";
import NavbarEmployer from "./components/common/NavbarEmployer";
import ProtectedRoute from "./components/common/ProtectedRoute";
import LayoutHomePage from "./components/common/LayoutHomePage";
import Footer from "./components/common/Footer";
import { Routes, Route } from "react-router-dom";
import { createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import authServices from "./services/AuthService";
import { login } from "./slices/userSlice";
import { getSavedJobs, getAppliedJobs } from "./slices/jobSlice";
import { getSavedCandidates } from "./slices/candidateSlice";
import "./App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

export const windowSizeContext = createContext(null);
const SignIn = lazy(() => import("./pages/users/SignIn"));
const SignUp = lazy(() => import("./pages/users/SignUp"));

const ForgotPassword = lazy(() => import("./pages/users/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/users/ResetPassword"));
const MyJobs = lazy(() => import("./pages/candidate/MyJobs"));
const Faq = lazy(() => import("./pages/Faq"));
const FaqHome = lazy(() => import("./pages/FaqHome"));
const PostedJobs = lazy(() => import("./pages/employer/PostedJobs"));
const AppliedCandidates = lazy(() =>
  import("./pages/employer/AppliedCandidates")
);
const ShortlistedCandidates = lazy(() =>
  import("./pages/employer/ShortlistedCandidates")
);
const SavedCandidates = lazy(() => import("./pages/employer/SavedCandidates"));
const AccountSettings = lazy(() =>
  import("./pages/accountSetting/AccountSettings")
);
const SearchJobsPage = lazy(() => import("./pages/employer/jobs/JobsPage"));

const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy"));

const NotificationsPage = lazy(() => import("./pages/NotificationsPage"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const PostJobPage = lazy(() =>
  import("./pages/employer/jobs/jobPosting/PostJobPage")
);

function App() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(false);
  const toggleLogin = () => {
    setIsLogin(!isLogin);
  };
  const [congratsModal, setCongratsModal] = useState(false);
  const toggleCongratsModal = () => setCongratsModal(!congratsModal);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const checkLogin = async (token) => {
        try {
          const res = await authServices.checkLogin(token);
          if (res.data.message === "User Logged In") {
            dispatch(login(res.data.user));

            if (res.data.user.type === "candidate") {
              dispatch(getSavedJobs(res.data.user._id));
              dispatch(getAppliedJobs(res.data.user._id));
            } else {
              dispatch(getSavedCandidates(res.data.user._id));
              dispatch(getAppliedJobs(res.data.user._id));
            }
            return;
          }
        } catch (error) {
          localStorage.removeItem("token");
        }
      };
      checkLogin(token);
    }
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <GoogleOAuthProvider clientId="929286673684-ci7oqgir73s27bltccpetm3ls8iqf6ml.apps.googleusercontent.com">
        <div className="App">
          {!user._id || user.type === "candidate" ? (
            <Navbar
              toggleLogin={toggleLogin}
              isLogin={isLogin}
              setIsLogin={setIsLogin}
              toggleCongratsModal={toggleCongratsModal}
            />
          ) : (
            <NavbarEmployer />
          )}
          <Toast />

          <Routes>
            <Route
              path="/jobs"
              exact
              element={<SearchJobsPage toggleLogin={toggleLogin} />}
            />
            {user && (
              <>
                <Route path="/postingJob" exact element={<PostJobPage />} />
                <Route path="/jobs/:id/:step" exact element={<PostJobPage />} />
                <Route path="/jobs/:id" element={<PostJobPage />} />
                <Route path="/Candidates" element={<AppliedCandidates />} />
              </>
            )}
            <Route element={<ProtectedRoute />}>
              <Route
                path="/notifications"
                exact
                element={<NotificationsPage />}
              />
              <Route path="/profile" exact element={<ProfilePage />} />
              <Route path="/postedJobs" exact element={<PostedJobs />} />
              <Route
                path="/savedCandidates"
                exact
                element={<SavedCandidates />}
              />
              <Route
                path="/shortlistedCandidates"
                element={<ShortlistedCandidates />}
              />
              <Route path="/accountSettings" element={<AccountSettings />} />
              <Route path="/myJobs" exact element={<MyJobs />} />
            </Route>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/reset_password" element={<ResetPassword />} />
            <Route
              path="/email_verification_resent"
              element={<EmailVerificationReSent />}
            />
            <Route
              path="/email_verification_sent"
              element={<EmailVerificationSent />}
            />
            <Route path="/confirm_email" element={<EmailVerification />} />
            <Route path="/Faq" exact element={<Faq />} />
            <Route path="/PrivacyPolicy" exact element={<PrivacyPolicy />} />
            <Route path="/TermsOfUse" exact element={<TermsOfUse />} />
            <Route path="/CookiePolicy" exact element={<CookiePolicy />} />
            <Route path="/FaqHome" exact element={<FaqHome />} />
            <Route
              path="/"
              exact
              element={<LayoutHomePage toggleLogin={toggleLogin} />}
            ></Route>
          </Routes>
          <Footer />
        </div>
      </GoogleOAuthProvider>
    </Suspense>
  );
}

export default App;
