import userReducer from "./slices/userSlice";
import jobReducer from "./slices/jobSlice";
import candidateReducer from "./slices/candidateSlice";
import alertReducer from "./slices/alertSlice";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: {
    user: userReducer,
    jobs: jobReducer,
    candidates: candidateReducer,
    alert: alertReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
export default store;
