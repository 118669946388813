import React, { useState, useEffect } from "react";
import authServices from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { calculateNewValue } from "@testing-library/user-event/dist/utils";
import checkedIcon from "../../assets/images/checked.png";
import Loader from "../../Loader";

const EmailVerification = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  let token = queryParams.get("token");
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(true);

  const closeModal = () => {
    setShow(false);
    navigate("/");
  };

  const navigateToPage = (page) => () => {
    navigate(`/${page}`);
  };

  useEffect(() => {
    if (!token) {
      setIsLoading(false);
    } else {
      authServices
        .verifyEmail(`token=${token}`)
        .then((data) => {
          if (data.data.error) {
            return alert(data.data.error);
          }
          setIsVerified(true);
        })
        .catch((error) => {
          alert(error.message);
        })
        .finally(setIsLoading(false));
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Modal show={show} onHide={closeModal} centered>
      <div className="border-purple  px-5 py-3">
        {isVerified ? (
          <>
            <Modal.Header closeButton />
            <Modal.Body className="p-4 py-5">
              <div className="d-flex justify-content-center align-items-center flex-column">
                <img src={checkedIcon} alt="congrats" />
                <h3 className="text-center mt-3 fw-bold text-black">
                  Congrats!
                </h3>
                <p className="text-center mt-3 text-muted">
                  Your account has been created. Get notified about new openings
                  right away and get hired faster!
                </p>
                <button
                  className="orange_btn mt-4"
                  onClick={navigateToPage("signin")}
                >
                  Let's Go
                </button>
              </div>
            </Modal.Body>
          </>
        ) : (
          <Modal.Body className="py-4">
            <p className="text-center">
              {" "}
              Cannot verify email! Please try again
            </p>
            <button
              className="d-block w-100 orange_btn py-2 px-4 mt-4"
              onClick={navigateToPage("email_verification_resent")}
            >
              Click here to re-send verification link
            </button>
          </Modal.Body>
        )}
      </div>
    </Modal>
  );
};

export default EmailVerification;
