import React from "react";
import Home from "../../pages/home/Home";
import { Outlet } from "react-router-dom";

const LayoutHomePage = ({ toggleLogin }) => {
  return (
    <>
      <Home toggleLogin={toggleLogin} />
      <main>
        <Outlet toggleLogin={toggleLogin} />
      </main>
    </>
  );
};

export default LayoutHomePage;
