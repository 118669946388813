import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import success from "../../../assets/images/success.png";

const AppliedSuccessfullyModal = ({
  isAppliedSuccessfully,
  toggleAppliedSuccessfully,
  jobTitle,
}) => {
  return (
    <Modal
      size="md"
      show={isAppliedSuccessfully}
      onHide={toggleAppliedSuccessfully}
      centered
    >
      <Modal.Header closeButton />

      <Modal.Body className="d-flex flex-column align-items-center py-5">
        <div>
          <img src={success} alt="success" />
        </div>
        <h3 className="fw-700 mt-4"> Applied Successfully</h3>
        <p className="mt-2 grey_color_text">
          You have successfully applied to the <strong>{jobTitle}</strong>{" "}
          Position
        </p>
        <button className="orange_btn mt-3" onClick={toggleAppliedSuccessfully}>
          Let's Go
        </button>
        {/* {handleApplyClick ? (
          <button
            className="orange_btn mt-3"
            onClick={() => {
              toggleAppliedSuccessfully();
              handleApplyClick(jobId);
            }}
          >
            Let's Go
          </button>
        ) : (
          <button
            className="orange_btn mt-3"
            onClick={toggleAppliedSuccessfully}
          >
            Let's Go
          </button>
        )} */}
      </Modal.Body>
    </Modal>
  );
};

export default AppliedSuccessfullyModal;
