import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/Logo_Hirenow.png";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RiNotification4Fill } from "react-icons/ri";
import { GiRoundStar } from "react-icons/gi";
import { FaUser } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../slices/userSlice";
import { addAlert } from "../../slices/alertSlice";
import "../../assets/styles/Nav.css";
import Loader from "../../Loader";

import authServices from "../../services/AuthService";
import { login } from "../../slices/userSlice";
import AccountType from "../modals/AccountType.js";

function Navbar({
  toggleLogin,
  setIsLogin,
  toggleCongratsModal,
  setSocialLogin,
}) {
  const user = useSelector((state) => state.user);

  const [accountType, setAccountType] = useState(false);
  const [userType, setUserType] = useState("");
  const [linkedInUser, setLinkedInUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOnClick = (link) => () => {
    navigate(`${link}`);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(logout());
    navigate("/?reload=true");
  };

  const handleNavigate = () => {
    navigate("/myJobs");
  };

  //###################################LINKEDIN LOGIN###########################/

  useEffect(() => {
    if (window.opener && window.opener !== window) {
      const popupWindowURL = new URL(window.location.href);
      const code = popupWindowURL.searchParams.get("code");
      window.opener.postMessage({ type: "code", code: code }, "*");
      window.close();
    }
    window.addEventListener("message", handlePostMessage);
  }, []);

  const handlePostMessage = (event) => {
    if (event.data.type === "code") {
      const { code } = event.data;
      getLinkedInCredentials(code);
    }
  };

  const accountTypeCallback = () => {
    if (userType === "") {
      return dispatch(
        addAlert({
          variant: "danger",
          message: "Please select user type",
        })
      );
    }
    saveLinkedInUser(linkedInUser, userType);
  };

  const getLinkedInCredentials = (code) => {
    setIsLoading(true);
    authServices.getLinkedInCredentials(code).then((res) => {
      if (res.data.error) {
        return dispatch(
          addAlert({
            variant: "danger",
            message: res.data.error,
          })
        );
      }
      setLinkedInUser(res.data);
      const flag = res.data.userExist;
      if (!flag) {
        setAccountType(true);
        setIsLogin(false);
        setIsLoading(false);
      } else {
        saveLinkedInUser(res.data, res.data.type);
      }
    });
  };

  const saveLinkedInUser = (user, type) => {
    user.type = type;
    authServices.linkedInLogin(user).then((res) => {
      if (res.data.error) {
        return dispatch(
          addAlert({
            variant: "danger",
            message: res.data.error,
          })
        );
      } else {
        setIsLoading(false);
        toggleLogin();
        setAccountType(false);
        setIsLogin(false);
        dispatch(login(res.data.user));
        localStorage.setItem("token", res.data.user.token);
        if (!user.userExist) {
          setSocialLogin(true);
          toggleCongratsModal();
          //navigate("/Profile", { state: { message: "Please update your profile"} });
        }
        navigate("/");
      }
    });
  };
  //###################################LINKEDIN LOGIN###########################/

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="bg-white">
      <AccountType
        accountType={accountType}
        setAccountType={setAccountType}
        userType={userType}
        setUserType={setUserType}
        accountTypeCallback={accountTypeCallback}
      />

      <Row className="w-95 nav_container m-auto d-flex py-4 justify-content-between align-items-center">
        <Col className="d-flex flex-columnm-0 ms-5 ps-5" md={3} xs={6}>
          <img
            src={Logo}
            width="160px"
            alt="Logo HireNow"
            className="nav_logo cursor_pointer"
            onClick={handleOnClick("/")}
          />
          {/* {windowSize > 800 && (
              <button className="nav_link" onClick={handleOnClick("jobs")}>
                Search Jobs
              </button>
            )}
            {windowSize > 1100 && (
              <button className="nav_link">Upload Resume</button>
            )}
          </div>
          <div className="right_items">
            {windowSize > 800 && (
              <button className="nav_link_right">For Employers</button>
            )} */}
        </Col>
        <Col className="d-flex me-5 pe-5 justify-content-end" md={3} xs={6}>
          {user._id ? (
            <div>
              <button
                onClick={handleOnClick("Notifications")}
                className="m-0 mx-3 profile_btn"
              >
                <RiNotification4Fill color="var(--purple-color)" size={25} />
              </button>
              <button className="m-0 mx-3 profile_btn">
                <GiRoundStar
                  color="var(--purple-color)"
                  onClick={() => handleNavigate()}
                  size={25}
                />
              </button>
              <div className="profile_dropdown">
                <button className="m-0 mx-3 profile_btn">
                  <FaUser color="var(--purple-color)" size={25} />
                </button>
                <div className="profile_dropdown_content">
                  <button
                    onClick={handleOnClick("Profile")}
                    className="profile_dropdown_item profile_btn m-0"
                  >
                    My Profile
                  </button>
                  <button
                    onClick={handleOnClick("MyJobs")}
                    className="profile_dropdown_item profile_btn m-0"
                  >
                    My Jobs
                  </button>
                  <button
                    onClick={handleOnClick("AccountSettings")}
                    className="profile_dropdown_item profile_btn m-0"
                  >
                    Account Settings
                  </button>
                  <button
                    onClick={handleOnClick("Faq")}
                    className="profile_dropdown_item profile_btn m-0"
                  >
                    FAQ's
                  </button>
                  <button
                    onClick={handleLogout}
                    className="profile_dropdown_item profile_btn m-0"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <button
              className="nav_link_btn m-0"
              onClick={handleOnClick("signin")}
            >
              Login | Signup
            </button>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default Navbar;
