import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { removeAlert } from "../../slices/alertSlice";
import { useNavigate } from "react-router-dom";

const Toast = () => {
  const showAlert = useSelector((state) => state.alert);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const closeAlert = () => {
    if (showAlert.navigate) {
      navigate(showAlert.navigate);
    }

    dispatch(removeAlert());
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      closeAlert();
    }, 3000);
    return () => clearTimeout(timer);
  }, [showAlert.message]);

  return (
    <>
      {showAlert.message && (
        <Alert
          variant={showAlert.variant}
          onClose={closeAlert}
          dismissible
          className="alert fixed-top w-50 start-50 translate-middle-x"
          style={{ zIndex: 9999 }}
        >
          {showAlert.message}
        </Alert>
      )}
    </>
  );
};

export default Toast;
