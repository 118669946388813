import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authServices from "../services/AuthService";

const initialState = { saved: [] };

export const getSavedCandidates = createAsyncThunk(
  "candidates/getSavedCandidates",
  async (userId) => {
    const response = await authServices.getSavedCandidates(userId);
    return response.data;
  }
);

export const saveCandidate = createAsyncThunk(
  "candidates/saveCandidate",
  async ({ userId, candidate }) => {
    await authServices.saveCandidate(userId, candidate._id);
    return { ...candidate, savedDate: Date.now() };
  }
);

export const removeSavedCandidate = createAsyncThunk(
  "candidates/unsaveCandidate",
  async ({ userId, candidate }) => {
    await authServices.unSaveCandidate(userId, candidate._id);
    return candidate;
  }
);

export const candidateSlice = createSlice({
  name: "candidate",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getSavedCandidates.fulfilled]: (state, { payload }) => {
      state.saved = payload;
    },
    [saveCandidate.fulfilled]: (state, { payload }) => {
      state.saved.push(payload);
    },
    [removeSavedCandidate.fulfilled]: (state, { payload }) => {
      state.saved = state.saved.filter((item) => item._id !== payload._id);
    },
  },
});

export default candidateSlice.reducer;
