import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { BiUpload } from "react-icons/bi";
import {
  BsBookmark,
  BsBookmarkFill,
  BsFillTelephoneFill,
  BsFillEnvelopeAtFill,
  BsGlobe,
  BsPinMapFill,
} from "react-icons/bs";
import { IoPersonSharp } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import myResumeIcon from "../../../assets/images/myResumeIcon.png";
import videoPlay from "../../../assets/images/videoplay.svg";
import parse from "html-react-parser";
import { useSelector, useDispatch } from "react-redux";
import { saveJob, removeSavedJob, applyJob } from "../../../slices/jobSlice";
import { addAlert } from "../../../slices/alertSlice";
import authServices from "../../../services/AuthService";
import AppliedSuccessfullyModal from "./AppliedSuccessfullyModal";
import { isValidFileUploaded } from "../../../assets/js/helper.js";
import loadingBar from "../../../assets/images/loading-bar.gif";

function JobDetailModal({
  toggleApplyModal,
  showApplyModal,
  showJobModal,
  toggleJobModal,
  jobs,
  jobIndex,
  setJobIndex,
}) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const savedJobs = useSelector((state) => state.jobs.saved);
  const appliedJobs = useSelector((state) => state.jobs.applied);
  if (user.type === "employer") {
    jobs = jobs.map((job) => ({ ...job, recruiter: user.employer }));
  }

  const selectedJob = jobs[jobIndex];

  console.log(selectedJob);
  const [files, setFiles] = useState({});
  const [isAppliedSuccessfully, setIsAppliedSuccessfully] = useState(false);
  const [isLoading, setIsLoading] = useState({
    resume: false,
    videoResume: false,
    referenceReview: false,
  });
  const toggleAppliedSuccessfully = () => {
    setIsAppliedSuccessfully(!isAppliedSuccessfully);
  };

  const prevJob = () => {
    setJobIndex((prev) => prev - 1);
  };

  const nextJob = () => {
    setJobIndex((prev) => prev + 1);
  };

  const upload = async (e) => {
    let type;
    if (e.target.id === "resume") {
      type = "document";
    } else {
      type = "video";
    }

    setIsLoading((prev) => ({ ...prev, [e.target.name]: true }));
    let checkValidFileUploaded = isValidFileUploaded(e.target.files[0], type);
    if (!checkValidFileUploaded.isValid) {
      setIsLoading((prev) => ({ ...prev, [e.target.name]: false }));
      return dispatch(
        addAlert({
          variant: "danger",
          message: checkValidFileUploaded.error,
        })
      );
    }
    const formData = new FormData();
    if (type === "document") {
      formData.append("documents", e.target.files[0]);
      await authServices
        .uploadDocument(formData)
        .then((res) => {
          if (res.data.error) {
            return alert(res.data.error);
          }
          setFiles((prev) => {
            return { ...prev, [e.target.name]: res.data[0] };
          });
        })
        .finally(() => {
          setIsLoading((prev) => ({ ...prev, [e.target.name]: false }));
        });
    } else {
      formData.append("videos", e.target.files[0]);
      await authServices
        .uploadVideo(formData)
        .then((res) => {
          if (res.data.error) {
            return alert(res.data.error);
          }
          setFiles((prev) => {
            return { ...prev, [e.target.name]: res.data[0] };
          });
        })
        .finally(() => {
          setIsLoading((prev) => ({ ...prev, [e.target.name]: false }));
        });
    }

    e.target.value = null;
  };

  const deleteFile = (documentName) => () => {
    setFiles((prev) => {
      let result = { ...prev };
      delete result[documentName];
      return result;
    });
  };

  const handleApply = () => {
    if (!user._id || user.type !== "candidate") {
      alert("Please log in as candidate to apply this job");
    } else {
      toggleApplyModal();
    }
  };

  const submit = async () => {
    if (!files.resume) {
      return dispatch(
        addAlert({ variant: "danger", message: "Please upload your resume" })
      );
    }
    const res = await dispatch(
      applyJob({ id: selectedJob._id, documents: files })
    );
    if (res.type === "jobs/applyJob/fulfilled") {
      toggleApplyModal();
      toggleJobModal();
      toggleAppliedSuccessfully();
    }
  };

  const handleUnsaveJob = () => {
    dispatch(removeSavedJob({ userId: user._id, job: selectedJob }));
  };
  const handleSaveJob = () => {
    dispatch(saveJob({ userId: user._id, job: selectedJob }));
  };

  if (selectedJob) {
    return (
      <>
        <Modal size="xl" show={showJobModal} onHide={toggleJobModal} centered>
          <Modal.Header closeButton />
          <Modal.Body className="custom_job_modal_body">
            <Row className="m-0 p-5 job_modal_container">
              {/* {showItems ? ( */}
              <>
                <Col
                  className="d-block text-center  modal_left_job_details p-3"
                  md={3}
                >
                  <p className="fw-bold  transparent_btn text-center fs-20 ">
                    {selectedJob.recruiter && selectedJob.recruiter.companyName}
                  </p>
                  <div className="company">
                    <img
                      src={selectedJob.recruiter.companyLogo}
                      width="100px"
                      alt=""
                    ></img>
                  </div>
                  <div className="fw-bold fs-12 text-muted  mt-3">
                    {selectedJob.recruiter && selectedJob.recruiter.phone && (
                      <p>
                        <BsFillTelephoneFill />
                        <span className="ms-2">
                          {selectedJob.recruiter.phone}{" "}
                        </span>{" "}
                      </p>
                    )}

                    {selectedJob.recruiter && selectedJob.recruiter.email && (
                      <p>
                        <BsFillEnvelopeAtFill />
                        <span className="ms-2">
                          {selectedJob.recruiter.email}
                        </span>
                      </p>
                    )}

                    {selectedJob.recruiter &&
                      selectedJob.recruiter.companyWebsite && (
                        <p>
                          <BsGlobe />
                          <span className="ms-2">
                            {selectedJob.recruiter.companyWebsite}
                          </span>
                        </p>
                      )}
                    {selectedJob.workspace && selectedJob.workspace.address && (
                      <p>
                        <BsPinMapFill />
                        <span className="ms-2">
                          {selectedJob.workspace &&
                            selectedJob.workspace.address &&
                            selectedJob.workspace.address.city &&
                            selectedJob.workspace.address.city}
                          {selectedJob.workspace &&
                          selectedJob.workspace.address &&
                          selectedJob.workspace.address.state
                            ? ", " + selectedJob.workspace.address.state
                            : ""}
                          {selectedJob.workspace &&
                          selectedJob.workspace.address &&
                          selectedJob.workspace.address.country
                            ? ", " + selectedJob.workspace.address.country
                            : ""}
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="d-blockn my-4">
                    {/* <span className="fw-bold">Intro Video</span>
                       <video
                        width="100%"
                        className="rounded"
                        src="https://www.w3schools.com/html/mov_bbb.mp4"
                        controls
                      />*/}
                  </div>
                  <div className="text-center fw-bold cursor-pointer save-sec">
                    {savedJobs.find((item) => item._id === selectedJob._id) ? (
                      <span onClick={handleUnsaveJob}>
                        <BsBookmarkFill
                          className="me-2 highlight"
                          color="var(--purple-color)"
                        />
                        Unsave job
                      </span>
                    ) : (
                      <span onClick={handleSaveJob}>
                        <BsBookmark
                          className="me-2 highlight"
                          color="var(--purple-color)"
                        />
                        Save the job
                      </span>
                    )}
                  </div>
                  {appliedJobs.find((job) => job._id === selectedJob._id) ? (
                    <button
                      className="btn py-3 px-5 mt-4"
                      style={{ backgroundColor: "#00D26A", color: "#ffffff" }}
                    >
                      ✅ Applied !
                    </button>
                  ) : (
                    <button
                      className="purple_btn py-3 px-5 fs-20 opacity-100 mt-4 text-nowrap"
                      onClick={handleApply}
                    >
                      Apply Now
                    </button>
                  )}

                  {/* {page != "applied" && (
                      <div className="text-center">
                        <span
                          className="fw-bold text-center cursor_pointer save-sec"
                          onClick={() => saveUnsaveJob(selectedJob._id)}
                        >
                          {savedStatus ? (
                            <span>
                              <BsBookmarkFill
                                className="me-2 highlight"
                                color="var(--purple-color)"
                              />
                              Unsave job
                            </span>
                          ) : (
                            <span>
                              <BsBookmark
                                className="me-2 highlight"
                                color="var(--purple-color)"
                              />
                              Save the job
                            </span>
                          )}
                        </span>

                 
                      </div>
                    )} */}
                </Col>
                <Col className="d-block" md={9}>
                  <Row className="m-0 p-0 w-100 justify-content-between">
                    <Col className=" d-flex justify-content-start" md={6}>
                      <h1 className="fw-bold transparent_btn fs-32 text-left text-nowrap">
                        {selectedJob.title}
                      </h1>
                    </Col>
                    <Col className="d-flex justify-content-end" md={6}>
                      <div className="text-right">
                        {jobIndex !== 0 && (
                          <button
                            className="reverse_purple_border_btn py-2 px-5 fs-20 opacity-100"
                            onClick={prevJob}
                          >
                            {" "}
                            Prev{" "}
                          </button>
                        )}

                        {jobIndex < jobs.length - 1 && (
                          <button
                            className="reverse_purple_border_btn py-2 px-5 fs-20 opacity-100 ms-2"
                            onClick={nextJob}
                          >
                            {" "}
                            Next{" "}
                          </button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="m-0 p-0 mt-3">
                    <Col className="d-flex flex-column justify-content-center align-items-start">
                      <span className="fw-bold">Job Description</span>
                      <p className="text-muted mt-3">
                        {selectedJob.description &&
                          parse(selectedJob.description)}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </>
              {/* ) : (
                <Col className="d-block" md={12}>
                  <Row className="m-0 p-0 w-100 justify-content-between">
                    <Col className=" d-flex justify-content-start" md={6}>
                      <h1 className="fw-bold transparent_btn fs-32 text-left text-nowrap">
                        {selectedJob.title}
                      </h1>
                    </Col>
                    <Col className="d-flex justify-content-end" md={6}>
                      <div className="text-right">
                        {jobIndex !== 0 && (
                          <button
                            className="reverse_purple_border_btn py-2 px-5 fs-20 opacity-100"
                            onClick={prevJob}
                          >
                            {" "}
                            Prev{" "}
                          </button>
                        )}

                        {jobIndex < jobs.length - 1 && (
                          <button
                            className="reverse_purple_border_btn py-2 px-5 fs-20 opacity-100 ms-2"
                            onClick={nextJob}
                          >
                            {" "}
                            Next{" "}
                          </button>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="m-0 p-0 mt-3">
                    <Col className="d-flex flex-column justify-content-center align-items-start">
                      <span className="fw-bold">Job Description</span>
                      <p className="text-muted mt-3">
                        {selectedJob.description &&
                          parse(selectedJob.description)}
                      </p>
                    </Col>
                  </Row>
                </Col>
              )} */}
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={showApplyModal}
          onHide={toggleApplyModal}
          dialogClassName="custom_modal_size"
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body className="custom_job_modal_body p-0">
            <div className="border-bottom p-4">
              <h1 className="fw-bold fs-32 text-nowrap">Apply for Job</h1>
            </div>

            <div className="p-4">
              <input
                type="file"
                id="resume"
                name="resume"
                hidden
                onChange={upload}
              />
              <label htmlFor="resume" className="w-100">
                <div className="p-4 form-control d-flex justify-content-between align-items-center cursor_pointer bg-transparent">
                  <div>
                    <img
                      src={myResumeIcon}
                      alt="resume"
                      className="me-2"
                      width="30px"
                      height="30px"
                    />
                    <span className="ms-2 transparent_btn fs-18">Resume</span>
                    <span className="text-danger">*</span>
                  </div>
                  <BiUpload size={30} color="#999999" />
                </div>
              </label>

              {isLoading.resume ? (
                <img src={loadingBar} />
              ) : (
                <>
                  {files.resume && (
                    <div className="ps-4 mt-2">
                      <a href={files.resume.url} target="_blank">
                        {files.resume.name}
                      </a>
                      <button
                        className="bg-transparent border-0 ms-3"
                        onClick={deleteFile("resume")}
                      >
                        <MdClose className="h-auto" color="red" />
                      </button>
                    </div>
                  )}
                </>
              )}

              <input
                type="file"
                id="videoResume"
                name="videoResume"
                hidden
                onChange={upload}
              />
              <label htmlFor="videoResume" className="w-100">
                <div className="p-4 form-control my-3 d-flex justify-content-between align-items-center cursor_pointer bg-transparent">
                  <div>
                    <img
                      src={videoPlay}
                      alt="video"
                      className="me-2"
                      width="30px"
                      height="30px"
                    />
                    <span className="ms-2 transparent_btn fs-18">
                      Video Resume
                    </span>
                    <span className="text-muted fs-18">(Optional)</span>
                  </div>
                  <BiUpload size={30} color="#999999" />
                </div>
              </label>
              {isLoading.videoResume ? (
                <img src={loadingBar} />
              ) : (
                <>
                  {files.videoResume && (
                    <div className="ps-4 mt-2 ">
                      <a href={files.videoResume.url} target="_blank">
                        {files.videoResume.name}
                      </a>
                      <button
                        className="bg-transparent border-0 ms-3"
                        onClick={deleteFile("videoResume")}
                      >
                        <MdClose className="h-auto" color="red" />
                      </button>
                    </div>
                  )}
                </>
              )}

              <input
                type="file"
                id="referenceReview"
                name="referenceReview"
                hidden
                onChange={upload}
              />
              <label htmlFor="referenceReview" className="w-100">
                <div className="p-4 form-control d-flex justify-content-between align-items-center cursor_pointer bg-transparent">
                  <div>
                    <IoPersonSharp color="var(--purple-color)" size={30} />
                    <span className="ms-2 transparent_btn fs-18">
                      References Review{" "}
                    </span>
                    <span className="text-muted fs-18">(Optional)</span>
                  </div>
                  <BiUpload size={30} color="#999999" />
                </div>
              </label>
              {isLoading.referenceReview ? (
                <img src={loadingBar} />
              ) : (
                <>
                  {files.referenceReview && (
                    <div className="ps-4 mt-2 ">
                      <a href={files.referenceReview.url} target="_blank">
                        {files.referenceReview.name}
                      </a>
                      <button
                        className="bg-transparent border-0 ms-3"
                        onClick={deleteFile("referenceReview")}
                      >
                        <MdClose className="h-auto" color="red" />
                      </button>
                    </div>
                  )}
                </>
              )}

              <button
                className="purple_btn py-3 px-5 fs-20 opacity-100 mt-4 w-100"
                onClick={submit}
              >
                Submit
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <AppliedSuccessfullyModal
          isAppliedSuccessfully={isAppliedSuccessfully}
          toggleAppliedSuccessfully={toggleAppliedSuccessfully}
          jobTitle={selectedJob.title}
        />
      </>
    );
  }
  return <></>;
}

export default JobDetailModal;
